import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import { PageTitle, useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import useRoles from '../../../../app/utils/roles/roles'

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  const { newCurrentUser} = useAuth()
  const { isSuperAdminHolos } = useRoles();

  return (
    <div className='app-navbar pe-5 flex-shrink-0 align-items-center'>
      {/* User Role Tag */}
      <div>
        { isSuperAdminHolos && (
          <h3 className='fw-bolder m-0 text-primary'>
            {newCurrentUser?.roles[0]}
          </h3>)}
      </div>

      {/* Theme Mode change */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      {/* User Settings */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          style={{
            width: '40px',
            height: '40px',
            background:
              'linear-gradient(83deg, rgba(0, 144, 249, 1) 0%, rgba(161, 144, 249, 1) 100%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
          }}
        >
          <span className='text-white fs-4'>
            {newCurrentUser?.firstName?.charAt(0)?.toUpperCase()}
            {newCurrentUser?.lastName?.charAt(0)?.toUpperCase()}
          </span>
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
