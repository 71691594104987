import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from 'react'

interface LoginFormStep1Props {
  onNext: (values: {email: string; password: string}) => void
}

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_URL ? process.env.REACT_APP_GOOGLE_RECAPTCHA_API_URL : 'undefined on env';

const LoginFormStep1: React.FC<LoginFormStep1Props> = ({onNext}) => {
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Formato de email incorrecto')
        .required('El email es un campo requerido'),
      password: Yup.string().required('La contraseña es un campo requerido'),
    }),
    onSubmit: (values) => {
      onNext(values)
    },
  })

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value);
  };
  
  return (
    <form
      id='kt_login_signin_form'
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {/* Title */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Iniciar Sesión</h1>
      </div>

      {/* Email Input */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          placeholder='Ingresa tu email'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      
      {/* Password Input */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 pb-2'>Contraseña</label>
        <input
         placeholder='Ingresa tu contraseña'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        <div className='recaptcha justify-content-center d-flex'>
        <ReCAPTCHA
          sitekey={GOOGLE_API_KEY}
          onChange={handleRecaptchaChange}
        />
        </div>
      </div>
      
      {/* Forgot Password Link */}
      <Row className='justify-content-end fs-base fw-semibold mb-8'>
        <Col className='col-12 text-end'>
        
          <Link to='/auth/forgot-password' className='brand-links'>
            ¿Olvidaste tu contraseña?
          </Link>
         
        </Col>
      </Row>
      
      {/* Login Button */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-ingresar'
          disabled={ !formik.isValid || !recaptchaValue}
        >
          <span className='indicator-label'>Iniciar Sesión</span>
        </button>
      </div>

      {/* Contact us Link */}
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        ¿No tienes cuenta?{' '}
        <a
          href='https://holos-tech.com/es/contactanos/'
          className='brand-links'
          target='_blank'
          rel='noopener noreferrer'
        >
          Contáctanos
        </a>
      </div>
    </form>
  )
}

export default LoginFormStep1
