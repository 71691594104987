import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import SecondFactorAlert from '../../app/pages/SecondAuthencationFactor/components/SecondFactorAlert'
import { useAuth } from '../../app/modules/auth'
import { Modal } from 'react-bootstrap'
import useIdleTimeout from '../../app/utils/idle-timer/idle-timer'
import { validateUserSession } from '../../app/modules/experiences/core/_requests'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'

const MasterLayout = () => {
  const location = useLocation()
  const {logout} = useAuth()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleIdle = () => {
    handleShow();
  }
  const {idleTimer} = useIdleTimeout({ onIdle: handleIdle, idleTime: 5 * 60 })
  const stay = () => {
    handleClose()
    idleTimer.reset()
  }
  const handleLogout = () => {
      logout()
      handleClose()
  }
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const RefreshSessionModal = () => {
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={true}
          keyboard={false}
          className='modal-login'
          centered
        >
          <Modal.Header closeButton style={{borderBottom: 'none'}} />
          <Modal.Body className='pt-0'>
            <h1 className='text-center'>
              Su sesión caducara debido a inactividad, ¿desea continuar?
            </h1>
            <div className='text-center pt-5 mt-5'>
              <button className='btn btn-ingresar-small' onClick={stay}>
                ¡Si, Aquí estoy!
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
  
    useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await validateUserSession(); 
      } catch (error) {
        console.log('error:', error);
        if (error instanceof Error) { // Checking if it's a regular error
          const axiosError = error as AxiosError; // Cast to AxiosError
          if (axiosError.response && axiosError.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'La sesión caducó.',
              text: 'Alguien ingresó con sus credenciales desde otro dispositivo o navegador. Vuelva a loguearse para renovar la sesión',
              confirmButtonText: 'Entiendo',
            }).then(() => {
              logout();
            });
          }
        }
      }
    }, 1 * 30 * 1000);
  
    return () => clearInterval(interval);
  }, []);

  return (
    <PageDataProvider>
      <SecondFactorAlert />
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <ToolbarWrapper />
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      <RefreshSessionModal/>
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
