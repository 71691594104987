import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/request-recover-password`
export const CREAR_USUARIO = `${API_URL}/usuario/crear/carga-individual`

export const loginRequest = (user) => axios.post(`${API_URL}/signin`, user)

export const registerRequest = (user) =>
  axios.post(`${API_URL}/usuario/crear/carga-individual`, user)

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestFA2(email: string) {
  return axios.post<{result: boolean}>(`${API_URL}/recover-two-factor-client`, {email})
}
export function checkCode(email: string, code: string) {
  return axios.post<{result: boolean}>(`${API_URL}/check-code/`, {email, code})
}

export function resetPassword(email: string, code: string,newPassword:string) {
  return axios.post<{result: boolean}>(`${API_URL}/reset-password/`, {email, code,newPassword})
}

// export async function changePassword(
//   bodyRequest: {
//     email: string;
//     oldPassword: string;
//     newPassword: string;
//     termsCondition: boolean;
//   }
// ) {
//   return await axios.post(`${API_URL}/enable-account/`, bodyRequest)
// }
export async function enableAccountPassword (bodyRequest: {
  email: string;
  oldPassword: string;
  newPassword: string;
  termsCondition: boolean;
}) {
  return axios.post<{result: boolean}>(`${API_URL}/enable-account/`, {
    email: bodyRequest.email, 
    newPassword: bodyRequest.newPassword, 
    code: bodyRequest.oldPassword,
    termsCondition: bodyRequest.termsCondition
  })
}

export function validateAuthCode(email: string, code: string) {
  return axios.post<{result: boolean}>(`${API_URL}/verify-email`, {email, code})
}