import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Fa2Page from '../pages/SecondAuthencationFactor/Fa2Page'

// PAGES
import GestionPage from '../modules/gestion/GestionPage'
import ConfigurationPage from '../modules/configuration/ConfigurationPage'
import useRoles from '../utils/roles/roles'
import useCorporationAccess from '../utils/roles/corporationAccess'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const AdministrationPage = lazy(() => import('../modules/administration/AdministrationPage'))
  const InteractiveContentPage = lazy(() => import('../modules/interactive-content/InteractiveContentPage'))
  const ExperiencesPage = lazy(() => import('../modules/experiences/ExperiencesPage'))
  const GestionPage = lazy(() => import('../modules/gestion/GestionPage'))
  const ConfigurationPage = lazy(() => import('../modules/configuration/ConfigurationPage'))

  const {isAdminCorporation, isSuperAdminHolos, isAreaManager, isDesarrollador, isInstructor} = useRoles()

  const {
    canAccessExperiences,
    canAccessManagement,
    canAccessAdministracion,
    canAccessMarket
  } = useCorporationAccess();

  const withAccessControl = (WrappedComponent, hasAccessFunc) => {
    return () => {
      const hasAccess = hasAccessFunc();
  
      return !hasAccess ? (
        <Navigate to='/dashboard' />
      ) : (
        <SuspensedView>
          <WrappedComponent />
        </SuspensedView>
      );
    };
  };
  
  const ProtectedExperienciasRoute = withAccessControl(ExperiencesPage, () => {
    return (isSuperAdminHolos || isAdminCorporation || isAreaManager || isInstructor) && canAccessExperiences;
  });

  const ProtectedGestionRoute = withAccessControl(GestionPage, () => {
    return (isSuperAdminHolos || isAdminCorporation || isAreaManager || isInstructor ) && canAccessManagement;
  });
  
  const ProtectedAdministrationRoute = withAccessControl(AdministrationPage, () => {
    return (isSuperAdminHolos || isAdminCorporation || isAreaManager || isInstructor || isDesarrollador) && canAccessAdministracion;
  });

  const ProtectedInteractiveContentRoute = withAccessControl(InteractiveContentPage, () => {
    return (isSuperAdminHolos || isAdminCorporation || isAreaManager || isInstructor || isDesarrollador) && canAccessAdministracion;
  });

  // const ProtectedMarketplaceRoute = withAccessControl(MarketplacePage, () => {
  //   return (isSuperAdminHolos || isAdminCorporation || isAreaManager || isInstructor) && canAccessMarket;
  // });

  const ProtectedConfigurationRoute = withAccessControl(ConfigurationPage, () => {
    return isSuperAdminHolos || isDesarrollador
  });

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />  
        <Route path='experiences/*' element={<ProtectedExperienciasRoute />} />   

        <Route path='administracion/*' element={<ProtectedAdministrationRoute />} />   

        <Route path='gestion/*' element={<ProtectedGestionRoute />} />   

        <Route path='configuration/*' element={<ProtectedConfigurationRoute />} />

        <Route path='interactive-content/*' element={<ProtectedInteractiveContentRoute />} />
        
        <Route path='second-factor/*' element={<Fa2Page />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/something-went-wrong' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
