import { createSlice } from '@reduxjs/toolkit'

const experienceSlice = createSlice({
  name: 'experiences',
  initialState: [],
  reducers: {
    updateExperiences(state, action) {
      return action.payload;
    },
  }
})

export const selectExperiences = state => state.experiences;
export const { updateExperiences } = experienceSlice.actions;
export default experienceSlice.reducer;