// import Cookies from 'js-cookie'

function getRefreshTokenCookie() {
    // const refreshToken = Cookies.get('refreshToken')
    const refreshToken = sessionStorage.getItem('refreshToken');
    return refreshToken || null
}

export function setupAxiosHeader(axios: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
      (config: {headers: {Authorization: string}}) => {
        const token = getRefreshTokenCookie()
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
  
        return config
      },
      (err: any) => Promise.reject(err)
    )
}
