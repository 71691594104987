import { useState } from "react";
import { useAuth } from "../../modules/auth";
import useRoles from "./roles";

function itIncludes(arr: string[], value: string) {
  return arr.includes(value);
}

function useCorporationAccess() {
  const { newCurrentUser } = useAuth();

  let canAccessManagement = true;
  let canAccessAdministracion = true;
  let canAccessExperiences = true;
  let canAccessMarket = true;

  const { 
    isDesarrollador, 
    isSuperAdminHolos, 
  } = useRoles()
  
  let totalAccess;

  if(!isSuperAdminHolos && !isDesarrollador){
    if(newCurrentUser?.corporations){
      console.log('current user corporation data', newCurrentUser?.corporations)
      const canAccess = newCurrentUser.corporations.map((c) => c.canAccess)
      console.log('canAccess', canAccess)
      totalAccess = canAccess.reduce((result, currentArray) => {return result.concat(currentArray);}, []);
      console.log('totalAccess', totalAccess)
      if(totalAccess.length > 0){
        canAccessManagement = itIncludes(totalAccess, 'management');
        canAccessAdministracion = itIncludes(totalAccess, 'administration');
        canAccessExperiences = itIncludes(totalAccess, 'experience');
        canAccessMarket = itIncludes(totalAccess, 'marketplace');
      }
    }
  }
  
  return {
    canAccessManagement,
    canAccessAdministracion,
    canAccessExperiences,
    canAccessMarket
  };
}

export default useCorporationAccess;