import axios, { AxiosError } from 'axios'
import { CORPORATIONS_URL, AREAS_URL, SIMULATORS_URL, USERS_URL, EXPERIENCES_URL, RISK_PROCEDURE_URL } from '../../../utils/_api_urls';
import { setupAxiosHeader } from '../../../utils/_axios_service';
import { removeNullParams } from '../../../utils/_remove_null_params';

setupAxiosHeader(axios);


export async function getCorporations() {
  return await axios.get(`${CORPORATIONS_URL}/listar` );
}

export async function getAreaByCorporation(
  filtros?: { 
    corporationsId?: string[],
    areasId?: string[]
  }
) {
  return await axios.post(`${AREAS_URL}/listar`, filtros)
}


export async function getSimulators(corporationId?) {
  let url = `${SIMULATORS_URL}/listar`;
  
  if (corporationId !== null && corporationId!=='') {
    url += `?corporationId=${corporationId}`;
  }
  return await axios.get(url );
}

export async function getLevelsBySimulator(simulatorId) {
  return await axios.get(`${RISK_PROCEDURE_URL}/bySimulator/${simulatorId}` );
}

export async function getInstructors(){
  return await axios.get(`${USERS_URL}/listarByRole/Instructor` );
}

export async function getUsersByRole( role:string, corporationId?:string, isContratista?: boolean,areaId?:string,){
  let queryParams = new URLSearchParams();

  if (corporationId) {queryParams.append('corporationId', String(corporationId));}
  if (areaId) {queryParams.append('areaId', String(areaId));}
  if (isContratista) {queryParams.append('isContratista', String(isContratista));}
  console.log(corporationId, isContratista)
  // const url = corporationId
  // ? `${USERS_URL}/listarByRole/${role}?corporationId=${corporationId}`
  // : `${USERS_URL}/listarByRole/${role}`;
  const queryString = queryParams.toString();
  console.log(queryString)
  return await axios.get(`${USERS_URL}/listarByRole/${role}?${queryString}`)
  // return await axios.get(url);
}

export async function getExperiences(filters,page = 1, pageSize = 10) {
  const queryParams = removeNullParams(filters)
  queryParams.append('page', String(page));
  queryParams.append('pageSize', String(pageSize));

  const queryString = queryParams.toString();
  const url = `${EXPERIENCES_URL}/listar?${queryString}`;
  const res = await axios.get(url);
  return res
}


export async function getExcel(filters,format) {
  const queryParams = removeNullParams(filters)
  queryParams.append('format', String(format));

  const queryString = queryParams.toString();
  const url = `${EXPERIENCES_URL}/excel?${queryString}`;
  const res = await axios.get(url);
  return res
}

export async function validateUserSession() {

  return await axios.post(`${process.env.REACT_APP_API_URL}/validate-user-session`);
}
