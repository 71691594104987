import {Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {ValidateAuthCode} from './components/ValidateAuthCode'
import {ForgotFa2} from './components/ForgotFA2'
import {ResetPassword} from './components/ResetPassword'
import Login from './components/Login'
import {AuthLayout} from './AuthLayout'
import VerifiedEmail from './components/VerifiedEmail'
import { ChangePassword } from './components/ChangePassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='recover-password' element={<ValidateAuthCode />} />
      <Route path='reset-password/:code' element={<ResetPassword />} />
      <Route path='forgotFa2' element={<ForgotFa2 />} />
      <Route path='verified-email' element={<VerifiedEmail />} />
      <Route path='change-password/:code' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
