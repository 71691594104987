import {createSlice} from '@reduxjs/toolkit'

const reloadSimulatorsSlice = createSlice({
  name: 'reloadSimulators',
  initialState: [],
  reducers: {
    updateReloadSimulators(state, action) {
      return action.payload
    },
  },
})

export const reloadSimulators = (state) => state.reloadSimulators
export const {updateReloadSimulators} = reloadSimulatorsSlice.actions
export default reloadSimulatorsSlice.reducer