import {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Outlet} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useAuth} from '../../modules/auth'
import Swal from 'sweetalert2'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

function SecondFactorLayout() {
  const [show, setShow] = useState(false)
  const {newCurrentUser, getRefreshToken, getInfoUser, handleAxiosErrorStatus} = useAuth()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const refreshToken = getRefreshToken()
  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_API_URL

  const handleDesactivarClick = async () => {
    const confirmation = await Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: 'Recuerda que si desactivas Google Authenticator, no tendrás que ingresar el código la próxima vez.',
      showCancelButton: true,
      confirmButtonText: 'Sí, desactivar',
      cancelButtonText: 'Cancelar',
    })

    if (confirmation.isConfirmed) {
      try {
        const response = await axios.get(`${API_URL}/disable-two-factor-auth`, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        })

        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Google Authenticator ha sido desactivado exitosamente.',
          confirmButtonText: 'Entiendo',
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();           
            if(newCurrentUser){
              getInfoUser(newCurrentUser?.id)
               navigate('/dashboard')
            }
           
          }
        });   
      } catch (error) {
        handleAxiosErrorStatus(error)
      }
    }
  }

  return (
    <>      
        <Row>
          <Col>
            {newCurrentUser?.isTwoFactorAuthenticationEnabled ? (
              <>
                <h1 className='pb-5'>Habilitar Google Authenticator</h1>
                <div className='card mb-10 position-relative mt-5'>
                  <div className='card-body d-flex align-items-center py-8'>
                    <p className='pt-5 text-gray-600 fw-semibold fs-6 mb-0 pb-1'>
                      Actualmente Google Authenticator está implementado correctamente. Si deseas
                      desactivarlo, presiona el siguiente enlace:{' '}
                      <span className='link-doble-factor' onClick={handleDesactivarClick}>
                        Desactivar Google Authenticator
                      </span>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h1 className='pb-5'>Habilitar Google Authenticator</h1>
                <div className='card mb-10 position-relative mt-5'>
                  <div className='card-body  py-8'>
                    <h4 className='pt-3 col-12'>¡Protege tu cuenta con doble seguridad!</h4>
                    <p className='pt-5 text-gray-600 fw-semibold fs-6 mb-0 pb-1 col-12'>
                      No arriesgues la información de tu cuenta. Asegúrala aún más activando el{' '}
                      <span className='text-gray-600 fw-bolder '>
                        Doble Factor de Autenticación
                      </span>{' '}
                      con Google Authenticator. Este proceso es{' '}
                      <span className='text-gray-600 fw-bolder'>sencillo</span> y agrega una capa
                      extra de <span className='text-gray-600 fw-bolder'>protección</span> a tu
                      cuenta.
                    </p>
                    <p className='pt-5 text-gray-600 fw-semibold fs-6 mb-0 pb-1 col-12'>
                      ¡Son solo <span className='text-gray-600 fw-bolder'>dos pasos</span>{' '}
                      necesarios para habilitar el 2FA ahora mismo!
                    </p>
                    <p className='pt-5 text-gray-600 fw-semibold fs-6 mb-0 pb-1 col-12'>
                      ¡No pierdas más tiempo! Activa el Doble Factor de Autenticación ahora y mantén
                      tu cuenta segura como nunca antes.{' '}
                      <span className='text-gray-600 fw-bolder'>Tus datos valen la pena.</span>
                    </p>
                    <div className='text-center pt-5 mt-5'>
                      <button className='btn btn-ingresar-small' onClick={handleShow}>
                        ¡Comencemos!
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>    
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
        className='modal-login '
        centered
      >
        <Modal.Header closeButton style={{borderBottom: 'none'}}></Modal.Header>
        <Modal.Body className=' pt-0'>
          <Outlet />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SecondFactorLayout
