import { createSlice } from '@reduxjs/toolkit'

const powerBISlice = createSlice({
  name: 'powerBIList',
  initialState: [],
  reducers: {
    updatePowerBIList(state, action) {
      return action.payload;
    },
  }
})

export const selectPowerBIList = state => state.powerBIList;
export const { updatePowerBIList } = powerBISlice.actions;
export default powerBISlice.reducer;