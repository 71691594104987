import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    updateUsers(state, action) {
      return action.payload;
    },
  }
})

export const selectUsers = state => state.users;
export const { updateUsers } = userSlice.actions;
export default userSlice.reducer;