import { createSlice } from '@reduxjs/toolkit'

const filterExperiencesSlice = createSlice({
  name: 'filterExperiences',
  initialState: [],
  reducers: {
    updateFilters(state, action) {
      return action.payload;
    },
  }
})

export const selectFiltersExperiences = state => state.filterExperiences;
export const { updateFilters } = filterExperiencesSlice.actions;
export default filterExperiencesSlice.reducer;