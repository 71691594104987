import React from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {useAuth} from '../../../modules/auth'
import Swal from 'sweetalert2'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const API_URL = process.env.REACT_APP_API_URL

function CodeStep() {
  const {newCurrentUser, getRefreshToken, getInfoUser, handleAxiosErrorStatus} = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      const refreshToken = getRefreshToken()   
      const response = await axios.get(`${API_URL}/enable-two-factor-auth/${formik.values.code}`, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Has activado correctamente Google Authenticator para proteger tu cuenta ',
          confirmButtonText: 'Entiendo',
        }).then((result) => {
          if (result.isConfirmed) {           
            // window.location.reload();
            if(newCurrentUser){
              getInfoUser(newCurrentUser?.id)
               navigate('/dashboard')
            }
           
          }
        })
      }
    } catch (error) {
      handleAxiosErrorStatus(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Este campo es requerido'),
    }),
    onSubmit: handleSubmit,
  })

  return (
    <Row>
      <Col className='col-12 mx-auto  py-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-8 form-group'>
            <label className='form-label fs-6 fw-bolder text-dark pb-3'>
              Luego, ingrese el código de google authenticator
            </label>
            <input
              type='text'
              id='code'
              className='form-control'
              placeholder='Código de autenticación de dos factores'
              {...formik.getFieldProps('code')}
            />
            {formik.touched.code && formik.errors.code ? (
              <div className='error pt-1  text-danger'>{formik.errors.code}</div>
            ) : null}
          </div>
          <button className='btn btn-ingresar' type='submit'>
            Enviar Código
          </button>
        </form>
      </Col>
    </Row>
  )
}

export default CodeStep
