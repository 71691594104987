import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
export const alertLocalStorageKey = 'secondFactorAlert'
function SecondFactorAlert() {
  
  const {newCurrentUser} = useAuth()

  // const [isAlertVisible, setIsAlertVisible] = useState(true)
  const [isAlertVisible, setIsAlertVisible] = useState(() => {
    const storedValue = localStorage.getItem(alertLocalStorageKey)
    return storedValue ? JSON.parse(storedValue) : true
  })

  useEffect(() => {
    localStorage.setItem(alertLocalStorageKey, JSON.stringify(isAlertVisible))
  }, [isAlertVisible])

  const handleCloseAlert = () => {
    setIsAlertVisible(false)
  }

  return (
    <>
      {newCurrentUser && !newCurrentUser.isTwoFactorAuthenticationEnabled && isAlertVisible && (
        <div className='card mb-1 position-relative mx-1'>
          <div className='card-body d-flex align-items-center  py-3 px-1 px-md-5 '>
            <div className='d-flex h-20px w-20px flex-shrink-0 flex-center position-relative d-none d-sm-flex ms-5 me-5'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='text-primary h-50px w-50px h-lg-50px w-lg-50px position-absolute opacity-5'
              >
                <path
                  fill='currentColor'
                  d='M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z'
                ></path>
              </svg>
              <KTIcon iconName='lock' className='fs-2x fs-lg-2x text-primary position-absolute' />
            </div>

            <div className='ms-6 pt-5 p-md-0'>
              <p className='list-unstyled text-gray-800 fw-bold fw-bolder fs-6 p-0 m-0'>
                ¡Refuerza la seguridad de tu cuenta!
              </p>
              <p className='list-unstyled text-gray-600 fw-bold fs-6 pt-1 p-0 m-0'>
                Activa el Doble Factor de Autenticación con{' '}
                <span className='text-gray-800 fw-bolder'>Google Authenticator</span> hoy mismo.
                Añade una capa adicional de protección a tu inicio de sesión y mantén tus datos
                seguros.{' '}
                <span>
                  {' '}
                  <Link to='/second-factor' className='brand-links'>
                    ¡No esperes, habilita el 2FA aquí!
                  </Link>
                </span>
              </p>
            </div>

            <button
              className='btn btn-sm btn-icon btn-active-color-primary position-absolute top-0 end-0 m-2'
              onClick={handleCloseAlert}
            >
              <i className='bi bi-x fs-3'></i>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default SecondFactorAlert
