import { ROLES } from "./rolEnum";


function useRoles() {
  const rolesUser = sessionStorage.getItem('userRoles');

  const isAdminCorporation = rolesUser?.includes(ROLES.AdminCorporacion);
  const isSuperAdminHolos = rolesUser?.includes(ROLES.SuperAdminHolos);
  const isInstructor = rolesUser?.includes(ROLES.Instructor);
  const isAreaManager = rolesUser?.includes(ROLES.AreaManager);
  const isDesarrollador = rolesUser?.includes(ROLES.Desarrollador);

  return {
    isAdminCorporation,
    isSuperAdminHolos,
    isInstructor,
    isAreaManager,
    isDesarrollador
  };
}

export default useRoles;