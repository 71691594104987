import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function LoginModal() {
  const navigate = useNavigate()
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => { 
    setShow(true); 
  }

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === '/auth/login' ||
      location.pathname.includes('/auth/change-password') || 
      location.pathname === '/auth/forgot-password' ||
      location.pathname.includes('/auth/reset-password')
    ) {
      // Si la URL es '/auth/change-password', muestra el modal
      handleShow();
    } else {
      // Si no es la URL deseada, oculta el modal
      handleClose();
    }
  }, [location]);
  
  return (
    <>
      {/* <button className='login-btn' onClick={() => {
        navigate('/auth/login');
        setShow(true); 
      }}>
        Iniciar Sesión
      </button> */}

      <Modal
        show={true}
        backdrop={true} 
        keyboard={false}
        className='modal-login ' 
      >
        <Modal.Header  style={{ borderBottom: 'none' }}>        
        </Modal.Header>
        <Modal.Body className=" pt-0" >
        <Outlet/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginModal;