/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'


const HeaderUserMenu: FC = () => {
  const {newCurrentUser, logout, getInfoUser} = useAuth()
  const navigate = useNavigate()
  function handleUserLogin() {
    if (newCurrentUser) {
      getInfoUser(newCurrentUser?.id)
      navigate('/profile')
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div
            className='symbol symbol-50px me-5'
            style={{
              width: '38px',
              height: '38px',
              background:
                'linear-gradient(83deg, rgba(0, 144, 249, 1) 0%, rgba(161, 144, 249, 1) 100%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            }}
          >
            <span className='text-white fs-4'>
              {newCurrentUser?.firstName?.charAt(0)?.toUpperCase()}
              {newCurrentUser?.lastName?.charAt(0)?.toUpperCase()}
            </span>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {newCurrentUser?.firstName} {newCurrentUser?.lastName}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {newCurrentUser?.email.address}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={handleUserLogin} className='menu-link px-5'>
          Mi Perfil
        </a>
      </div>

      
      <div
        className='menu-item px-5 d-none d-md-block'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>Configuraciones</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-200px py-4'>
          <div className='menu-item px-3'>
            <Link to='/second-factor' className='menu-link px-5'>
              Google Authenticator
            </Link>
          </div>
          {/* <div className='menu-item px-3'>
            <Link to='/asdf' className='menu-link px-5'>
              Prueba Error 404
            </Link>
          </div> */}
        </div>
      </div>
      <div className='accordion config d-md-none' id='kt_accordion_1'>
        <div className='accordion-item menu-link'>
          <p className=' title-accordion-config  px-4  menu-title m-0' id='kt_accordion_1_header_1'>
            <button
              className='accordion-button fs-4 fw-bold title-accordion-config  collapsed py-3 '
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_accordion_1_body_1'
              aria-expanded='false'
              aria-controls='kt_accordion_1_body_1'
            >
              Configuraciones
            </button>
          </p>
          <div
            id='kt_accordion_1_body_1'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_1'
            data-bs-parent='#kt_accordion_1'
          >
            <div className='accordion-body py-1'>
              <div className='menu-item px-3'>
                <Link to='/second-factor' className='menu-link px-5'>
                  Google Authenticator
                </Link>
              </div>
              <div className='menu-item px-3'>
                <Link to='/asdf' className='menu-link px-5'>
                  Prueba Error 404
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Cerrar Sesión
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
