import {Route, Routes} from 'react-router-dom'
import SecondFactorLayout from './SecondFactorLayout'
import QrStep from './components/QrStep'
import FirstStep from './components/FirstStep'
import CodeStep from './components/CodeStep'

function Fa2Page() {
  return (
    <Routes>
      <Route element={<SecondFactorLayout />}>
        <Route path='first-step' element={<FirstStep />} />
        <Route path='/second-step' element={<QrStep />} />
        <Route path='/third-step' element={<CodeStep />} />
        <Route index element={<FirstStep />} />
      </Route>
    </Routes>
  )
}

export default Fa2Page
