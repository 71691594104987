import {createSlice} from '@reduxjs/toolkit'

const reloadSubscriptionsSlice = createSlice({
  name: 'reloadSubscriptions',
  initialState: [],
  reducers: {
    updateReloadSubscriptions(state, action) {
      return action.payload
    },
  },
})

export const reloadSubscriptions = (state) => state.reloadSubscriptions
export const {updateReloadSubscriptions} = reloadSubscriptionsSlice.actions
export default reloadSubscriptionsSlice.reducer