import {useEffect} from 'react'
import NavbarIndex from './components/NavbarIndex'
import CarouselIndex from './components/CarouselIndex'
import FooterIndex from './components/IndexFooter'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoginModal from './components/LoginModal'
const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#07091d'
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  return (
    <div className='bg-index'>
      <Container>
        {/* <NavbarIndex />  */}
        <LoginModal />
     
        {/* <Row className='py-xxl-5'>
          <Col className='col-12 pt-5 '>
            <h1 className='text-white display-6 text-center col-12 title-index'>
              Diseñamos Experiencias en <span className='brand-color'>Realidad Virtual</span> y{' '}
              <span className='brand-color'>Realidad Aumentada</span>
            </h1>
          </Col>
          <Col className='pt-5 col-12 pb-3'>
            <h5 className='text-center subtitle'>
              Generamos ambientes en los cuales el sujeto toma decisiones e interactúa con
              información digital y con el mundo real en el mismo contexto y período de tiempo.
            </h5>
          </Col>
        </Row>
        <CarouselIndex />  */}
      </Container>
      {/* <FooterIndex /> */}
    </div>
  )
}

export {AuthLayout}
