import {createSlice} from '@reduxjs/toolkit'

const reloadAreasSlice = createSlice({
  name: 'reloadAreas',
  initialState: [],
  reducers: {
    updateReloadAreas(state, action) {
      return action.payload
    },
  },
})

export const reloadAreas = (state) => state.reloadAreas
export const {updateReloadAreas} = reloadAreasSlice.actions
export default reloadAreasSlice.reducer