import {createSlice} from '@reduxjs/toolkit'

const reloadCorporationsSlice = createSlice({
  name: 'reloadCorporations',
  initialState: [],
  reducers: {
    updateReloadCorporations(state, action) {
      return action.payload
    },
  },
})

export const reloadCorporations = (state) => state.reloadCorporations
export const {updateReloadCorporations} = reloadCorporationsSlice.actions
export default reloadCorporationsSlice.reducer