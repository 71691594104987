import {createSlice} from '@reduxjs/toolkit'

const filterMyPowerBISlice = createSlice({
  name: 'filterMyPowerBI',
  initialState: [],
  reducers: {
    updatefilterMyPowerBI(state, action) {
      return action.payload
    },
  },
})

export const selectfilterMyPowerBI = (state) => state.filterMyPowerBI
export const {updatefilterMyPowerBI} = filterMyPowerBISlice.actions
export default filterMyPowerBISlice.reducer
