import React from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
// import {useAuth} from '../../../modules/auth'
import Swal from 'sweetalert2'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const API_URL = process.env.REACT_APP_API_URL

function VerifiedEmail() { 
  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {     
      const response = await axios.post(`${API_URL}/verify-email`,{code: formik.values.code,email: formik.values.email,},{});

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Email verificado correctamente',
          text: 'Ahora deberás iniciar sesión con su email',
          confirmButtonText: 'Entiendo',
        }).then((result) => {
          if (result.isConfirmed) {  
            navigate('/auth')                     
          }
        })
      }
    } catch (error) {  
      console.log('algo salio mal')   
    }
  }
  const formik = useFormik({
    initialValues: {
      code: '',
      email:'',
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Este campo es requerido'),
      email: Yup.string().required('Este campo es requerido'),
    }),
    onSubmit: handleSubmit,
  })

  return (
    <Row>
      <Col className='col-12 mx-auto  py-3'>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-2'>Verifique su email</h1>
        <p className='pt-5 text-gray-600 fw-semibold fs-6 pt-2 pb-5 mb-5'>Por favor, revise su correo electrónico y luego complete el siguiente formulario</p>
      </div>
        <form onSubmit={formik.handleSubmit}>
        <div className='fv-row mb-8 form-group'>
            <label className='form-label fs-6 fw-bolder text-dark pb-3'>
           Email
            </label>
            <input
              type='text'
              id='email'
              className='form-control'
              placeholder='Ingrese su email'
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='error pt-1  text-danger'>{formik.errors.email}</div>
            ) : null}
          </div>
          
          <div className='fv-row mb-8 form-group'>
            <label className='form-label fs-6 fw-bolder text-dark pb-3'>
            Ingrese el código enviado a su email
            </label>
            <input
              type='text'
              id='code'
              className='form-control'
              placeholder='Código de verificación'
              {...formik.getFieldProps('code')}
            />
            {formik.touched.code && formik.errors.code ? (
              <div className='error pt-1  text-danger'>{formik.errors.code}</div>
            ) : null}
          </div>
          <button className='btn btn-ingresar' type='submit'>
            Enviar Código
          </button>
        </form>
      </Col>
    </Row>
  )
}

export default VerifiedEmail
