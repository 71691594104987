import {createSlice} from '@reduxjs/toolkit'

const reloadActivitiesSlice = createSlice({
  name: 'reloadActivities',
  initialState: [],
  reducers: {
    updateReloadActivities(state, action) {
      return action.payload
    },
  },
})

export const reloadActivities = (state) => state.reloadActivities
export const {updateReloadActivities} = reloadActivitiesSlice.actions
export default reloadActivitiesSlice.reducer