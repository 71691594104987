import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Error404: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>ERROR</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-5 text-gray-500 mb-7'>
        Se ha producido un problema. Por favor, regresa a la página de inicio
      </div>
      {/* end::Text */}
      <Row>
        <Col className='mx-auto '>
          {/* begin::Illustration */}
          <div className='pb-5'>
            <img
              src={toAbsoluteUrl('/media/img/error/error.svg')}
              className='mw-100 mh-300px '
              alt=''
            />
          </div>
          {/* end::Illustration */}
        </Col>
      </Row>

      {/* begin::Link */}
      <Row>
        <Col className='mx-auto col-11 col-md-4'>
          <div className='mb-0 pt-5 '>
            <Link to='/dashboard' className='btn btn-ingresar'>
              Volver a Inicio
            </Link>
          </div>
        </Col>
      </Row>

      {/* end::Link */}
    </>
  )
}

export {Error404}
