import { createSlice } from '@reduxjs/toolkit'

const pageSizeExperienceSlice = createSlice({
  name: 'experiencesPageSize',
  initialState: 10,
  reducers: {
    updateExperiencesPageSize(state, action) {
      return action.payload;
    },
  }
})

export const selectedExperiencesPageSize = (state) => state.experiencesPageSize;
export const { updateExperiencesPageSize } = pageSizeExperienceSlice.actions;
export default pageSizeExperienceSlice.reducer;