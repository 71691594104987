import { createSlice } from '@reduxjs/toolkit'

const filterPowerBISlice = createSlice({
  name: 'filterPowerBI',
  initialState: [],
  reducers: {
    updateFilters(state, action) {
      return action.payload;
    },
  }
})

export const selectFiltersExperiences = state => state.filterPowerBI;
export const { updateFilters } = filterPowerBISlice.actions;
export default filterPowerBISlice.reducer;