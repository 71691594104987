import React, { useState } from 'react';
import LoginFormStep2 from './LoginFormStep2';
import {AxiosError} from 'axios'
import { useAuth } from '../core/Auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import {useNavigate} from 'react-router-dom'
import LoginFormStep1 from './LoginFormStep1';
import {loginRequest} from '../core/_requests'

const API_URL = process.env.REACT_APP_API_URL

const Login: React.FC = () => {
  const {logout} = useAuth()
  const { getInfoUser, setIsAuthenticated, decodeToken, setUser, signin} = useAuth();
  const [step, setStep] = useState(1);
  const navigate = useNavigate()
  const [userData, setUserData] = useState<{ email: string; password: string } | null>(null);

  const handleStep1Submit = async (values: { email: string; password: string }) => {
    try {
      const response = await axios.post(`${API_URL}/is-two-factor-enabled`, { email: values.email });

      if (response.data === false) {
        try {
          const res = await loginRequest(values);
          setUser(res.data);
          const userFromToken = decodeToken(res.data.refreshToken);

          if (res.data.accessToken && userFromToken) {
            sessionStorage.setItem('accessToken', res.data.accessToken);
            sessionStorage.setItem('refreshToken', res.data.refreshToken);
            sessionStorage.setItem("auth","true");
            setIsAuthenticated(true);
          }
          
          if (userFromToken && res.status === 200) {
            await getInfoUser(userFromToken.id);
          }
        } catch (error: unknown) {
          if (error instanceof AxiosError) {
            const errorData = error.response?.data
            if (errorData) {
              if (errorData.statusCode === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'La sesión caducó',
                  text: 'Vuelva a loguearse para renovar la sesión',
                  confirmButtonText: 'Entiendo',
                }).then(() => {
                  logout();
                })
              }
              if(
                errorData.message.includes('Este email no ha sido validado.') ||
                errorData?.code === "AUTH_ERR_001"
              ) {              
                navigate(`/auth/change-password/${values.password}`)
              } else {               
                Swal.fire({
                  icon: 'error',
                  title: 'ERROR',
                  text: errorData?.message,
                  confirmButtonText: 'Entiendo',                  
                })
              }
            }
          } else {
            console.log(error)
          }
        }
      } else {
        setUserData(values);
        setStep(2);
      }
    } catch (error: unknown) { 
      if (error instanceof AxiosError) {
        const errorData = error.response?.data
        if (errorData) {
          if (errorData.statusCode === 401) {
            const errorMessage = 'La combinación de email y contraseña es incorrecta.'
            // const errorMessage = errorData.message
            Swal.fire({
              icon: 'error',
              title: 'ERROR',
              text: errorMessage,
              confirmButtonText: 'Entiendo',
            })
          }else{               
            Swal.fire({
              icon: 'error',
              title: 'ERROR',
              text: errorData.message,
              confirmButtonText: 'Entiendo',                  
            })
          }
        }
      } else {
        console.log(error)
      }
    }
  };

  const handleStep2Submit = async (values: { twoFactorAuthenticationCode: string }) => {
    try {
      const combinedData = { ...userData!, ...values };
      await signin(combinedData);
    } catch (error) {
      console.error('Error en la autenticación:', error);
    }
  };

  return (
    <div>
      {step === 1 && <LoginFormStep1 onNext={handleStep1Submit} />}
      {step === 2 && <LoginFormStep2 onLogin={handleStep2Submit} />}
    </div>
  );
};

export default Login;


