import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useNavigate} from 'react-router-dom'

function FirstStep() {
  const navigate = useNavigate()

  const handleHabilitarClick =  () => {
        navigate('/second-factor/second-step')    
  }

  return (
    <Container>
      <Row>
        <Col className='col-12 mx-auto text-center'>
          <h3 className='text-center '>Paso 1: Descarguemos Google Authenticator</h3>      
          <p className='text-gray-600 fw-semibold fs-6 mt-0 pt-5 pb-5'>¡Presiona el botón a continuación para empezar!</p>
          <div className='col-5 mx-auto py-5 my-5 '>
            <a
              href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
             
              rel='noopener noreferrer'
            >
              <img
                src={`${process.env.PUBLIC_URL}/media/img/playStoreBtn.png`}
                alt='Miniatura'
                style={{maxWidth: '100%', maxHeight: '100%'}}
                className='image-hover'
              />
            </a>
          </div>

          <p className='pt-5 text-gray-600 fw-semibold fs-6 pt-5 pb-5 mb-5'>
          Es una app que te permitirá generar códigos de 2FA desde tu smartphone para acceder y
            operar en nuestra plataforma
          </p>
          <button onClick={handleHabilitarClick} className='btn btn-ingresar pt-5 fs-4'>
            Habilitar
          </button>
        </Col>
      </Row>
    </Container>
  )
}

export default FirstStep
