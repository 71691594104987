import {createSlice} from '@reduxjs/toolkit'

const reloadTrainingCampaignsSlice = createSlice({
  name: 'reloadTrainingCampaigns',
  initialState: 0,
  reducers: {
    updateReloadTrainingCampaigns(state, action) {
      return action.payload
    },
  },
})

export const reloadTrainingCampaigns = (state) => state.reloadTrainingCampaigns
export const {updateReloadTrainingCampaigns} = reloadTrainingCampaignsSlice.actions
export default reloadTrainingCampaignsSlice.reducer