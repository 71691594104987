import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { checkCode, requestPassword } from '../core/_requests'
import { Spinner } from 'react-bootstrap'
import OtpInput from '../../../common/components/OTPInput'
import Swal from 'sweetalert2'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de email incorrecto')
    .required('El email es un campo requerido')
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isMailSent, setIsMailSent] = useState<boolean>(false);
  const [isOtpInputDisabled, setOtpInputDisabled] = useState<boolean>(false);
  const [showCodeError, setShowCodeError] = useState<boolean>(false);
  const [otp, setOtp] = useState('');
  const onChange = (value: string) => setOtp(value);
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
            setIsMailSent(true);
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Los detalles de inicio de sesión son incorrectos')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      if (otp.trim().length === 6) {
        setOtpInputDisabled(false);
        const requestBody = {
          email: formik.values.email,
          code: otp
        };
        try {
          const request = await checkCode(requestBody.email, requestBody.code);
          if(request.status == 200){
            Swal.fire({
              icon: 'success',
              title: '¡Éxito!',
              text: 'Código ingresado correctamente, se le dirigirá al cambio de contraseña.',
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              setLoading(false);
              navigate('/auth/reset-password/'+requestBody.code);
            });
          } else {
          }
        } catch (error) {
          setShowCodeError(true);
        }
      } else {
        setShowCodeError(false);
      }
    };
  
    fetchData(); // Call the asynchronous function immediately
  
  }, [otp]);
  
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>¿Olvidaste tu contraseña?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Ingresa tu correo electrónico para restablecer tu contraseña
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            El e-mail ingresado no existe
          </div>
        </div>
      )}

      {hasErrors === false && (
        <>
          <div className=' bg-light-info p-8 rounded'>
            <div className='h4 text-align-justify'>¡Listo! Hemos enviado un correo electrónico con las instrucciones para restablecer tu contraseña. Ingrese el código recibido:</div>
            <br />
            {/* <Link to='/auth/recover-password' className='brand-links'>
            Haz click aquí para ingresar el código que llegó a tu correo.
          </Link> */}
            <div>
              <div className='d-flex row justify-content-center'>
                <OtpInput value={otp} valueLength={6} onChange={onChange} isDisabled={isOtpInputDisabled}/>
                {showCodeError && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block d-flex justify-content-center mt-8 '>
                      <span className='h4 text-danger' role='alert'>El código ingresado no es válido.</span>
                    </div>
                  </div>)}
              </div>
            </div>
          </div>

        </>
      )}
      {/* end::Title */}

      {!isMailSent && (
        <>
          {/* begin::Form group */}
          < div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' disabled={formik.isSubmitting} className='btn btn-primary me-4'>
              {!(formik.isSubmitting) && (
                <span className='indicator-label d-flex justify-content-center'>
                  Enviar Correo
                </span>
              )}
              {formik.isSubmitting && (
                <span className='indicator-label d-flex justify-content-center'>
                  Verificando correo
                  <span className='d-flex align-items-center ms-3'>
                    <Spinner />
                  </span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancelar
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </>
      )
      }
    </form>
  )
}
