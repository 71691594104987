import {createSlice} from '@reduxjs/toolkit'

const reloadVersionSlice = createSlice({
  name: 'reloadVersion',
  initialState: [],
  reducers: {
    updateReloadVersion(state, action) {
      return action.payload
    },
  },
})

export const reloadVersion = (state) => state.reloadVersion
export const {updateReloadVersion} = reloadVersionSlice.actions
export default reloadVersionSlice.reducer