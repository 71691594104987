const API_URL = process.env.REACT_APP_API_URL

export const CORPORATIONS_URL = `${API_URL}/corporation`;
export const AREAS_URL = `${API_URL}/area`;
export const SIMULATORS_URL = `${API_URL}/simulator`;
export const VERSIONS_URL = `${API_URL}/version`;
export const RISK_PROCEDURE_URL = `${API_URL}/risk-procedure`;
export const USERS_URL = `${API_URL}/usuario`;
export const EXPERIENCES_URL = `${API_URL}/sims`;
export const POWERBIBOARDS_URL = `${API_URL}/powerbiboards`;
export const ROLES_URL = `${API_URL}/roles`;
export const INDUSTRY_TYPE_URL = `${API_URL}/industry-type`;
export const COUNTRY_URL = `${API_URL}/country`;
export const LICENCE_URL = `${API_URL}/licence`;
export const ACTIVITY_URL = `${API_URL}/activity`;
export const TYPES_URL = `${API_URL}/types`;
export const TRAINING_CAMPAIGNS_URL = `${API_URL}/distribution`;
export const SUBSCRIPTION_URL = `${API_URL}/subscription`