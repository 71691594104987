import { createSlice } from '@reduxjs/toolkit';

const simulatorTypeSlice = createSlice({
  name: 'simulatorType',
  initialState: null, // Initial state should be null, assuming simulatorType is a single value, not an array
  reducers: {
    updateSimulatorType(state, action) {
      // Modify the state directly with the payload value
      return action.payload;
    },
  },
});

export const selectSimulatorType = (state) => state.simulatorType ?? null;
export const { updateSimulatorType } = simulatorTypeSlice.actions;
export default simulatorTypeSlice.reducer;