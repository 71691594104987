import {createSlice} from '@reduxjs/toolkit'

const filterUsersSlice = createSlice({
  name: 'filterUsers',
  initialState: [],
  reducers: {
    updatefilterUsers(state, action) {
      return action.payload
    },
  },
})

export const selectfilterUsers = (state) => state.filterUsers
export const {updatefilterUsers} = filterUsersSlice.actions
export default filterUsersSlice.reducer