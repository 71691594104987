import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { enableAccountPassword, resetPassword } from '../core/_requests';
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap'

const initialValues = {
  newPassword: '',
  email: '',
  confirmPassword: '',
  code: '',
  passValues: true,
}


// const resetPasswordSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Formato de email incorrecto')
//     .required('El email es un campo requerido'),
//   newPassword: Yup.string()
//     .min(3, 'Minimo 3 caracteres')
//     .max(50, 'Máximo 50 caracteres')
//     .required('El campo password es obligatorio'),  
//   confirmPassword: Yup.string()
//     .min(3, 'Minimo 3 caracteres')
//     .max(50, 'Máximo 50 caracteres')
//     .required('El campo password es obligatorio')
//     .oneOf([Yup.ref('newPassword')], 'Los passwords no coinciden'),
// })
interface passwordValidationsInterface {
  has8Chars: boolean,
  hasMayusMins: boolean,
  hasNumbers: boolean,
  hasSpecialChars: boolean
};

export function ResetPassword() {
  const location = useLocation();
  const isFirstLogin = location.pathname.includes('/auth/change-password');
  const url = window.location.toString();
  const urlCortada = url.split('/');
  initialValues.code = urlCortada[urlCortada.length - 1];
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [termsCondition, setTermsCondition] = useState<boolean>(false);
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      const requestBody = {
        email:values.email,
        oldPassword:initialValues.code,
        newPassword: values.newPassword
      }
      if(!isFirstLogin){
        setTimeout(() => {
          resetPassword(requestBody.email,requestBody.oldPassword,requestBody.newPassword)
            .then(({ data: { result } }) => {
              setHasErrors(false)
              setLoading(false)
              Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Tu contraseña se ha actualizado correctamente, se le redigirá al inicio de sesión.',
                showConfirmButton: false,
                timer: 4000,
              }).then(() => {
                setLoading(false);
                navigate('/auth/login');
              });
            })
            .catch(() => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus('Los detalles de inicio de sesión son incorrectos')
            })
        }, 1000)
      } else {
        try {
          const response = await enableAccountPassword({...requestBody, termsCondition});
          
          if (response.status === 200) {
            setHasErrors(false);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: '¡Contraseña Actualizada!',
              text: 'Tu contraseña se ha actualizado con éxito. Ahora puedes iniciar sesión con tu nueva contraseña.',
              confirmButtonText: 'Iniciar sesión',
            }).then(() => {
              navigate('/auth/login');
            });
          } else {
            console.error('Error en la petición a la API:', response.status);
          }
        } catch (error) {
          console.error('Error en la petición a la API:', error);
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus('Los detalles de inicio de sesión son incorrectos');
          // Handle the error appropriately, e.g., show an error message to the user
        }
        // setTimeout(() => {
        //   change(values.email, initialValues.code, values.newPassword)
        //     .then(({ data: { result } }) => {
        //       setHasErrors(false)
        //       setLoading(false)
        //       Swal.fire({
        //         icon: 'success',
        //         title: '¡Éxito!',
        //         text: 'Tu contraseña se ha actualizado correctamente, se le redigirá al inicio de sesión.',
        //         showConfirmButton: false,
        //         timer: 4000,
        //       }).then(() => {
        //         setLoading(false);
        //         navigate('/auth/login');
        //       });
        //     })
        //     .catch(() => {
        //       setHasErrors(true)
        //       setLoading(false)
        //       setSubmitting(false)
        //       setStatus('Los detalles de inicio de sesión son incorrectos')
        //     })
        // }, 1000)
      }

    },
  })
  if (formik.getFieldProps('newPassword').value != formik.getFieldProps('confirmPassword').value) {
    initialValues.passValues = false;
  } else {
    initialValues.passValues = true;
  }

  const [passwordValidations, setPasswordValidations] = useState<passwordValidationsInterface>(
    {
      has8Chars: false,
      hasMayusMins: false,
      hasNumbers: false,
      hasSpecialChars: false
    }
  )

  const toggleTermsCondition = () => {
    setTermsCondition(!termsCondition);
    console.log("asdas")
  };

  useEffect(() => {
    //Validate the password requirementos
    const numberRegex = /\d/;
    const upperLowerRegex = /^(?=.*[a-z])(?=.*[A-Z])/;
    const specialCharRegex = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]/;

    const password = formik.values.newPassword;
    setPasswordValidations({
      has8Chars: password.length >= 8,
      hasNumbers: numberRegex.test(password),
      hasMayusMins: upperLowerRegex.test(password),
      hasSpecialChars: specialCharRegex.test(password)
    });
  }, [formik.values.newPassword]);

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-5'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>{isFirstLogin ? '¡Bienvenido a SymbioXr!' : 'Reestablecer contraseña'}</h1>
        {/* end::Title */}
        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Ingrese su nueva contraseña
        </div>
        {/* end::Link */}
      </div>
      {isFirstLogin && (
        <div className='mb-10 bg-light-info p-6 rounded'>
          <div className='h5 semi-bold'>
            Por motivos de seguridad, crea una nueva contraseña segura y confidencial para proteger tu
            cuenta.
          </div>
        </div>
      )}
      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Lo sentimos, parece que se han detectado algunos errores. Por favor, inténtalo nuevamente.
          </div>
        </div>
      )}

      {/* {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>¡Listo! Tu contraseña se ha reestablecido correctamente</div>
          <br/>
          <Link to='/auth' className='brand-links'>
                  Haz click aquí para ingresar con tu nueva contraseña.
          </Link>
        </div>
      )} */}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* <div className='d-flex flex-wrap align-items-center mb-8'> */}
      <div id='kt_signin_password' className={' ' + ('d-none')}>
        <div className='fv-row mb-8 '>Nueva contraseña</div>
      </div>

      <div className='fv-row mb-8'>
        <label htmlFor='newPassword' className='form-label fs-6 fw-bolder mb-3'>
          Nueva contraseña
        </label>
        <input
          type='password'
          className={`form-control form-control-lg form-control-solid ${passwordValidations.has8Chars &&
              passwordValidations.hasMayusMins &&
              passwordValidations.hasNumbers &&
              passwordValidations.hasSpecialChars
              ? 'is-valid'
              : 'is-invalid'
            }`}
          id='newPassword'
          {...formik.getFieldProps('newPassword')}
        />
        <div>
        </div>
      </div>


      <div className='fv-row mb-6'>
        <label htmlFor='confirmPassword' className='form-label fs-6 fw-bolder mb-3'>
          Confirmar nueva contraseña
        </label>
        <input
          type='password'
          className={`form-control form-control-lg form-control-solid ${formik.touched.confirmPassword &&
              formik.values.newPassword !== formik.values.confirmPassword
              ? 'is-invalid'
              : formik.touched.confirmPassword
                ? 'is-valid'
                : ''
            }`}
          id='confirmPassword'
          {...formik.getFieldProps('confirmPassword')}
        />
        {!initialValues.passValues && (
          <div className='mt-5 text-danger'>
            Las contraseñas ingresadas no coinciden
          </div>
        )}
      </div>

      <div>
        La contraseña debe cumplir los siguientes requisitos:
        <ul>
          <li>
            Contener al menos 8 caracteres {passwordValidations.has8Chars ? (
              <span className='ms-1 h3 text-success'>&#10004;</span>
            ) : (
              <span className='ms-1 h3 text-danger'>&#10008;</span>
            )}
          </li>
          <li>
            Contener mayúsculas y minúsculas {passwordValidations.hasMayusMins ? (
              <span className='ms-1 h3 text-success'>&#10004;</span>
            ) : (
              <span className='ms-1 h3 text-danger'>&#10008;</span>
            )}
          </li>
          <li>
            Contener números {passwordValidations.hasNumbers ? (
              <span className='ms-1 h3 text-success'>&#10004;</span>
            ) : (
              <span className='ms-1 h3 text-danger'>&#10008;</span>
            )}
          </li>
          <li>
            Contener caracteres especiales {passwordValidations.hasSpecialChars ? (
              <span className='ms-1 h3 text-success'>&#10004;</span>
            ) : (
              <span className='ms-1 h3 text-danger'>&#10008;</span>
            )}
          </li>
        </ul>
      </div>
      {/* </div> */}
      {isFirstLogin && (
        <div className='row'>
          <div className='col-8 mx-auto pt-3 d-flex fv-row align-items-center  text-center '>
            <div className='form-check form-check-custom form-check-solid '>
              <input
                className='form-check-input me-3'
                type='checkbox'
                id='verifiedCheckbox'
                checked={termsCondition}
                onChange={toggleTermsCondition}
              />
              <label className='form-check-label'>
                <Link to='#'>
                  <div className='fw-bolder text-gray-800'>Acepto los Términos y Condiciones</div>
                </Link>
              </label>
            </div>
          </div>
          <div className='col-8 mx-auto pt-3 d-flex fv-row align-items-center  text-center '>
            {/* {formik.touched.verified && formik.errors.verified && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.verified}</span>
            </div>
          </div>
        )} */}
          </div>
        </div>
      )

      }

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center mt-10 pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'
          disabled={
            formik.values.newPassword !== formik.values.confirmPassword ||
            !passwordValidations.has8Chars ||
            !passwordValidations.hasMayusMins ||
            !passwordValidations.hasNumbers ||
            !passwordValidations.hasSpecialChars ||
            loading ||
            (!termsCondition && isFirstLogin)
          }
        >
          <span className='indicator-label  d-flex'>Guardar contraseña
            {loading && (
              <span className='d-flex align-items-center ms-3'>
                <Spinner />
              </span>
            )}

          </span>
          {loading && (
            <span className='indicator-progress'>
              Por favor, espere
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancelar
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
