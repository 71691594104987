import React from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'

interface LoginFormStep2Props {
  onLogin: (values: {twoFactorAuthenticationCode: string}) => void
}

const LoginFormStep2: React.FC<LoginFormStep2Props> = ({onLogin}) => {
  const formik = useFormik({
    initialValues: {
      twoFactorAuthenticationCode: '',
    },
    validationSchema: Yup.object({
      twoFactorAuthenticationCode: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      onLogin(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* begin::Heading */}
      <div className='text-center mb-11'>

        <h1 className='text-dark fw-bolder mb-3'>Segundo Factor de Autenticación</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Ingrese el código de Google Authenticator aquí.
        </div>
      </div>
      {/* end::Heading */}
      {/* begin::Form group */}
      <div className='form-group pb-3'>
        <label
          className='form-label fs-6 fw-bolder text-dark'
          htmlFor='twoFactorAuthenticationCode'
        >
          Código
        </label>
        <input
          type='text'
          id='twoFactorAuthenticationCode'
          className='form-control'
          {...formik.getFieldProps('twoFactorAuthenticationCode')}
        />
        {formik.touched.twoFactorAuthenticationCode && formik.errors.twoFactorAuthenticationCode ? (
          <div className='error'>{formik.errors.twoFactorAuthenticationCode}</div>
        ) : null}
        {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />
          <small className='pt-3'>*Este campo solo es opcional si no está usando Google Authenticator</small>
        </div> */}
      
      </div>
    
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />    
        {/* <Link to='/auth/forgotFa2' className='brand-links'>
        ¿Perdió la opción de doble factor? Recupérela aquí
        </Link>        */}
      </div>
      <button className='btn btn-ingresar ' type='submit'>
        Continuar
      </button>
   
    </form>
  )
}

export default LoginFormStep2
