/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'


const DashboardWrapper: FC = () => {
  const intl = useIntl()
  
  // const [show, setShow] = useState(false)
  // const [showModalExpire, setShowModalExpire] = useState(false)

  // const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)

  // // function to set inactivity and log out
  // const checkForInactivity = () => {
  //   // triggers the RefreshSessionModal
  //   handleShow()
  //   const exp = sessionStorage.getItem('expireTime')
  //   const expireTime = Number(exp)
  //   if (expireTime && expireTime < Date.now()) {
  //     logout()
  //   }
  // }

  // // function to update the expire time
  // const updateExpireTime = () => {
  //   handleClose()
  //   // set the expire 5 minit after last activity
  //   const expireTime = (Date.now() + 10 * 60 * 1000).toString()
  //   sessionStorage.setItem('expireTime', expireTime)
  // }

  // const RefreshSessionModal = () => {
  //   return (
  //     <>
  //       <Modal
  //         show={show}
  //         onHide={handleClose}
  //         backdrop={true}
  //         keyboard={false}
  //         className='modal-login'
  //         centered
  //       >
  //         <Modal.Header closeButton style={{borderBottom: 'none'}} />
  //         <Modal.Body className='pt-0'>
  //           <h1 className='text-center'>
  //             Su sesión caducara debido a inactividad, ¿desea continuar?
  //           </h1>
  //           <div className='text-center pt-5 mt-5'>
  //             <button className='btn btn-ingresar-small' onClick={updateExpireTime}>
  //               ¡Si, Aquí estoy!
  //             </button>
  //           </div>
  //         </Modal.Body>
  //       </Modal>
  //     </>
  //   )
  // }

  // useEffect(() => {
  //   // Open the modal every 5 minutes
  //   const interval = setInterval(() => {
  //     checkForInactivity()
  //     handleShow()
  //   }, 5 * 60 * 1000)

  //   // Cleanup the interval when the component unmounts
  //   return () => clearInterval(interval)
  // }, [])


  

  // useEffect(() => {
  //   // Set initial expire time
  //   updateExpireTime()
  // }, [])


  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
    </>
  )
}
export { DashboardWrapper }

