import {createContext, useState, useContext, useEffect, Dispatch, SetStateAction} from 'react'
import {loginRequest, registerRequest} from '../core/_requests'
import {AxiosError} from 'axios'
import jwtDecode from 'jwt-decode'
import {UserModel} from './_models'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
// import Cookies from 'js-cookie'
import axios from 'axios'
import { UserProfile } from '../../../models'

import { alertLocalStorageKey } from '../../../pages/SecondAuthencationFactor/components/SecondFactorAlert.tsx'
import { getCorporationInfo } from '../../corporations/core/_requests.ts'

const API_URL = process.env.REACT_APP_API_URL

interface AuthProviderProps {
  children: React.ReactNode
}

type UserDataType = {
  email: string
  password: string
  twoFactorAuthenticationCode?: string
}

type AuthContextType = {
  signin: (user: UserDataType) => Promise<void>
  signup: (user: UserDataType) => Promise<void>
  user: UserDataType | null
  setUser:Dispatch<SetStateAction<UserDataType | null>>
  isAuthenticated: boolean
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  errorResponse: string | null
  getAccessToken: () => {}
  logout: () => void
  newCurrentUser: UserProfile | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  getRefreshToken: () => string | null | undefined
  handleAxiosErrorStatus: (error: unknown) => void;
  getInfoUser: (userId: string) => Promise<void>;
  setnewCurrentUser: Dispatch<SetStateAction<UserProfile | undefined>>
  decodeToken: (token: string) => UserModel | null;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider = ({children}: AuthProviderProps) => {
  const [user, setUser] = useState<UserDataType | null>(null)
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [newCurrentUser, setnewCurrentUser] = useState<UserProfile | undefined>()
  const [currentUserCorp, setCurrentUserCorp] = useState<UserProfile | undefined>()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [errorResponse, setErrorResponse] = useState('')
  const [accessToken, setAccessToken] = useState<string>('')

  function getAccessToken() {
    return accessToken
  }

  const decodeToken = (token: string): UserModel | null => {
    try {
      const decodedToken: any = jwtDecode(token)
      const user: UserModel = {
        id: decodedToken.sub || '',
        roles: decodedToken.roles || [],

        email: decodedToken.email || '',
        // isTwoFactorAuthenticationEnabled: decodedToken.isTwoFactorAuthenticationEnabled || false,
        exp: decodedToken.exp || 0,   
      }
      return user
    } catch (error) {
      console.error('Error decoding token:', error)
      return null
    }
  } 

  function getRefreshToken() {
    // const refreshToken = Cookies.get('refreshToken')
    const refreshToken = sessionStorage.getItem('refreshToken');
    return refreshToken || null
  }

  const signin = async (user: UserDataType) => {   
    try {
      const res = await loginRequest(user)
      setUser(res.data)
      const userFromToken = decodeToken(res.data.refreshToken)
      if (res.data.accessToken && userFromToken) {
        sessionStorage.setItem('accessToken', res.data.accessToken);
        sessionStorage.setItem('refreshToken', res.data.refreshToken);
        setIsAuthenticated(true)
      }
      if (userFromToken && res.status === 200) {
        await getInfoUser(userFromToken.id); 
      }      
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data
        if (errorData) {
          if (errorData.statusCode === 401) {
            Swal.fire({
              icon: 'error',
              title: 'La sesión caducó',
              text: 'Vuelva a loguearse para renovar la sesión',
              confirmButtonText: 'Entiendo',
            }).then(() => {
              logout();
            })
          }else if(errorData.message === 'Este email no ha sido validado. Te hemos enviado un correo con las intrucciones para continuar con el proceso de validación.') {
            
            const errorMessage = errorData.message
            Swal.fire({
              icon: 'warning',
              title: 'Importante',
              text: errorMessage,
              confirmButtonText: 'Ingresar Código',
            }).then((result) => {
              if (result.isConfirmed) {                     
                console.log('ir a vista para ingresar el codigo de validacion del email')                         
              }
            })
          }else {
            setErrorResponse(errorData.message)
            Swal.fire({
              icon: 'error',
              title: 'ERROR',
              text: errorData.message,
              confirmButtonText: 'Entiendo',
              
            })
            console.log(errorData.error)
            console.log(errorData.message)
            console.log(errorData.statusCode)
          }
        }
      } else {
        console.log(error)
      }
    }
  }


// Usar esta funcion si la peticion nos devuelve 401
  const handleAxiosErrorStatus = (error) => {
    sessionStorage.removeItem('auth');
    if (error instanceof AxiosError) {
      const errorData = error.response?.data;
      if (errorData) {
        if (errorData.statusCode === 401) {
          sessionStorage.clear();
          // Cookies.remove('accessToken');
          // Cookies.remove('refreshToken');
          window.location.reload();
        }
      }
    }
  };
  
  const logout = async () => {
    sessionStorage.removeItem('auth');
    try {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const response = await axios.post(`${API_URL}/logout`, null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
  
        // Elimina las cookies y los estados de autenticación
        setAccessToken('');
        // Cookies.remove('accessToken');
        // Cookies.remove('refreshToken');
        sessionStorage.clear();
        setIsAuthenticated(false);
        setUser(null);
        localStorage.setItem(alertLocalStorageKey, JSON.stringify(true));
      } else {
        console.log('No se encontró el refreshToken en el session storage.');
      }
    } catch (error) {
      handleAxiosErrorStatus(error);
    }
  };


  const getInfoUser = async (userId) => {
    try {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const response = await axios.get(`${API_URL}/usuario/profile/${userId}`, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
  
        if (response.status === 200) {       
          const userData = response.data;        
          
          const userRoles = userData.roles;
          if (userRoles) {
            sessionStorage.setItem('userRoles', JSON.stringify(userRoles));
          } 
          setnewCurrentUser(userData)
        }
      } else {
        console.log('No se encontró el usuario');
      }
    } catch (error) {
      handleAxiosErrorStatus(error);
    }
  };
  

  const signup = async (user: UserDataType) => {
    try {
      const res = await registerRequest(user)
      setUser(res.data)
    } catch (error) {
      console.error('signup error', error)
    }
  }

  // Use effect to decode token and set user data
  useEffect(() => {
    // const storedAccessToken = Cookies.get('accessToken')
    const storedAccessToken = sessionStorage.getItem('accessToken');
    if (storedAccessToken) {
      setAccessToken(storedAccessToken)
      setIsAuthenticated(true)

      const user = decodeToken(storedAccessToken)

      if (user) {       
        getInfoUser(user.id); 
      }  
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        setIsAuthenticated,
        decodeToken,
        setUser,
        signin,
        user,
        signup,
        isAuthenticated,
        errorResponse,
        getAccessToken,
        logout,
        getInfoUser,
        // currentUser,
        setCurrentUser,
        getRefreshToken,
        handleAxiosErrorStatus,
        newCurrentUser,
        setnewCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
