import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios'
import {useAuth} from '../../../modules/auth'
import CodeStep from './CodeStep'
const API_URL = process.env.REACT_APP_API_URL

function QrStep() {
  const {getRefreshToken, handleAxiosErrorStatus} = useAuth()
  const [qrResponse, setQrResponse] = useState<string | undefined>(undefined)

  useEffect(() => {
    async function fetchQrCode() {
      try {
        const refreshToken = getRefreshToken()
        const response = await axios.get(`${API_URL}/turn-two-factor-auth`, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        })

        if (response.status === 200) {
          setQrResponse(response.data)         
        }
      } catch (error) {
        handleAxiosErrorStatus(error)
      }
    }

    fetchQrCode()
  }, [])

  return (
    <Container>
      <Row>
        <Col className='col-12 mx-auto text-center pb-5'>
          <h4 className='text-center'>Paso 2: Escanea el siguiente código QR</h4>
          <p className='pt-5 text-gray-600 fw-semibold fs-6 mb-0 pb-1'>
            Dentro de la app presiona el signo + para agregar una nueva cuenta a través del código
            QR
          </p>

          <div className='pt-5 pb-1'>
            <img src={qrResponse} alt='QR Código' />
          </div>
        </Col>
      </Row>
      <CodeStep />
    </Container>
  )
}

export default QrStep
