import {createSlice} from '@reduxjs/toolkit'

const reloadRisksSlice = createSlice({
  name: 'reloadRisks',
  initialState: [],
  reducers: {
    updateReloadRisks(state, action) {
      return action.payload
    },
  },
})

export const reloadRisks = (state) => state.reloadRisks
export const {updateReloadRisks} = reloadRisksSlice.actions
export default reloadRisksSlice.reducer