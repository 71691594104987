import {createSlice} from '@reduxjs/toolkit'

const myPowerBISlice = createSlice({
  name: 'myPowerBIList',
  initialState: [],
  reducers: {
    updateMyPowerBIList(state, action) {
      return action.payload
    },
  },
})

export const selectMyPowerBIList = (state) => state.myPowerBIList
export const {updateMyPowerBIList} = myPowerBISlice.actions
export default myPowerBISlice.reducer
