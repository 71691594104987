import {configureStore} from '@reduxjs/toolkit'

import experienceReducer from './experienceSlice'

import filtersExperiencesSlice from './filtersExperiencesSlice'
import filtersPowerBISlice from './filtersPowerBISlice'
import powerBISlice from './powerBISlice'
import myPowerBISlice from './myPowerBISlice'
import simulatorTypeSlice from './simulatorTypeSlice'
import filterMyPowerBISlice from './filterMyPowerBISlice'
import usersSlice from './usersSlice'
import filterUsersSlice from './usersFilters'
import pageSizeExperienceSlice from './pageSizeExperienceSlice'
import filterAreasSlice from './filterAreasSlice'
import reloadAreasSlice from './reloadAreasSlice'
import reloadCorporationsSlice from './reloadCorporationsSlice'
import reloadSubscriptionsSlice from './reloadSubscriptionsSlice'
import reloadSimulatorsSlice from './reloadSimulatorsSlice'
import reloadVersionsSlice from './reloadVersionsSlice'
import reloadRisksSlice from './reloadRisksSlice'
import reloadActivitiesSlice from './reloadActivitiesSlice'
import reloadTrainingCampaignsSlice from './reloadTrainingCampaignsSlice'
export default configureStore({
  reducer: {
    experiences: experienceReducer,
    filterExperiences: filtersExperiencesSlice,
    experiencesPageSize: pageSizeExperienceSlice,
    powerBIList: powerBISlice,
    myPowerBIList: myPowerBISlice,
    filterPowerBI: filtersPowerBISlice,
    filterMyPowerBI: filterMyPowerBISlice,
    simulatorType: simulatorTypeSlice,
    users: usersSlice,
    filterUsers: filterUsersSlice,
    filterAreas: filterAreasSlice,
    reloadAreas: reloadAreasSlice,
    reloadCorporations: reloadCorporationsSlice,
    reloadSubscriptions: reloadSubscriptionsSlice,
    reloadSimulators: reloadSimulatorsSlice,
    reloadVersions: reloadVersionsSlice,
    reloadRisks: reloadRisksSlice,
    reloadActivities: reloadActivitiesSlice,
    reloadTrainingCampaigns: reloadTrainingCampaignsSlice
  },
})
