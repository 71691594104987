import {createSlice} from '@reduxjs/toolkit'

const filterAreasSlice = createSlice({
  name: 'filterAreas',
  initialState: [],
  reducers: {
    updatefilterAreas(state, action) {
      return action.payload
    },
  },
})

export const selectfilterAreas = (state) => state.filterAreas
export const {updatefilterAreas} = filterAreasSlice.actions
export default filterAreasSlice.reducer